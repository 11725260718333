/**
 * @module parseURLParams
 * @description Utils for url params
 */

/**
 * @function consolidateURLParams
 * @description Removes duplicate URL params
 */
export const consolidateURLParams = (
  params
) => params.reduce( ( prev, [ key, value ] ) => {
  if ( ! prev ) {
    return {
      [ key ]: value
    };
  }

  return {
    ...prev,
    [ key ]: value
  };
}, {} );

/**
 * @function parseURLParams
 * @description Extracts URL params from a string and returns them as an object
 */
export const parseURLParams = ( url ) => {
  // eslint-disable-next-line no-unused-vars
  const [ _, params ] = url.split( '?' );

  if ( ! params || ! params.split( '&' ) ) {
    return {};
  }

  return consolidateURLParams( params.split( '&' ).map( ( param ) => param.split( '=' ) ) );
};
