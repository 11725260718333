/**
 * @module PhoneButton
 * @description
 */
import { useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import { CohesionContext } from '@red-digital/bricks';
import { trackEvent } from '@/js/helpers/tracking';
import styles from '../DynamicButton/index.module.css';
import { formatPhoneNumber } from '@/js/helpers/fuse';

/**
 * @function PhoneButton
 * @description
 */
export const PhoneButton = ( { ...props } ) => {
  delete props.href; // we need to remove the href prop otherwise we could end up going to cart
  const { defaultFuseNumber, fuseNumber } = useContext( CohesionContext );
  const [ phoneNumber, setPhoneNumber ] = useState( '' );
  const { position, theme } = props;

  useEffect( () => {
    setPhoneNumber( formatPhoneNumber( fuseNumber || defaultFuseNumber ) );
  }, [ defaultFuseNumber, fuseNumber ] );

  /**
   * @function handleClick
   * @description
   */
  const handleClick = ( event ) => {
    const data = {
      elementType: 'BUTTON',
      position,
      text: 'Phone Number',
      actionOutcome: 'CLICKTOCALL'
    };

    trackEvent( { action: 'elementClicked', data, event } );
  };

  return (
    <Link
      href={ `tel:${ phoneNumber || 18664994271 }` }
      onClick={ handleClick }
      data-type="phone"
      className={ `btn ${ styles.component__btn } ${ theme && `btn--${ theme }` }` }
      { ...props }
    >
      CALL <span className='icon icon-phone-outline' /> { phoneNumber || '1-866-499-4271' }
    </Link>
  );
};

export default PhoneButton;
