/**
 * @module Monarch Helper
 * @description
 */
import { parseURLParams } from '@/js/utils/parseURLParams';
import { getCookie } from '@/js/utils/cookie';
import {
  BAU_RULESET, CDAPI_TEST_RULESET, LP_MONARCH_SOURCE, LP_MONARCH_TOKEN
} from '@/js/constants/cohesion';

// Any url param formatting for the Monarch CDAPI Request payload
const constructCdapiPayload = () => {
  const urlParams = parseURLParams( window.location.href );
  const formattedUrlParams = {};

  const { utm_tech } = urlParams;

  let isExpansion = false;
  if ( utm_tech === 'fiberexpansion' ) {
    isExpansion = true;
  } else {
    isExpansion = false;
  }

  formattedUrlParams.isExpansion = isExpansion;

  const payload = {
    formattedUrlParams: { ...formattedUrlParams }
  };
  return payload;
};

export const callMonarchWithPreamp = ( preampDecisions ) => {
  const apiVersion = getCookie( 'apiVersion' );
  if ( typeof preampDecisions === 'object' ) {
    // check editable asset property
    let cdapiPlacement;
    let cdapiMonarchRuleset;

    // Look for CDAPI asset + placement pair
    Object.entries( preampDecisions ).forEach( ( [ placementId, asset ] ) => {
      const { metadata = {} } = asset;
      if ( metadata.isCdapiAgent && typeof metadata.monarchRulesetId === 'string' ) {
        cdapiMonarchRuleset = metadata.monarchRulesetId;
        cdapiPlacement = placementId;
      }
    } );

    // If pair exists, use specified Monarch ruleset
    if ( cdapiMonarchRuleset && cdapiPlacement ) {
      const payload = {
        ...constructCdapiPayload(),
        olVersion: apiVersion
      };

      const rulesetId = process.env.environment === 'local' ? CDAPI_TEST_RULESET : cdapiMonarchRuleset;
      const config = {
        rulesetId,
        payload,
        monarchParams: {
          sourceId: LP_MONARCH_SOURCE,
          token: LP_MONARCH_TOKEN
        }
      };

      return config;
    }
  }

  // Else, use BAU Monarch ruleset
  return {
    rulesetId: BAU_RULESET,
    payload: {
      olVersion: apiVersion
    },
    monarchParams: {
      sourceId: LP_MONARCH_SOURCE,
      token: LP_MONARCH_TOKEN
    }
  };
};
