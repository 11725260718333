/**
 * @module Helpers
 * @description Some handy helpers
 */
const fastlyBase = process.env.imageDomain;

/**
 * @function generateIdNumber
 * @description Generate 20 digit random integer
 */
export const generateIdNumber = () => Math.round( Math.random() * 10000000 ).toString() + new Date().getTime();

/**
 * @function getAsset
 * @description Get asset from basePath
 */
export const getAsset = ( asset = '' ) => `${ asset }`;

/**
 * @function localImage
 * @description Our local image loader
 */
export const localImage = ( asset ) => getAsset( asset.src );

/**
 * @function fastly
 * @description Our local image loader
 */
export const fastly = ( asset ) => {
  let base = `${ fastlyBase }${ asset.src }`;
  if ( asset.width || asset.height ) {
    base += `?width=${ asset.width }&height=${ asset.height }`;
  }

  return base;
};

/**
 * @function fastlyBlur
 * @description Our fastly blur helper
 */
export const fastlyBlur = ( src ) => `${ fastlyBase }${ src }?width=10`;
